import React from 'react'
import CallToActionSection from '../../components/call_to_action_section'
import CenterHero from '../../components/hero/center_hero'
// import { EnterpriseConnectionsHeroBackground } from '../../components/hero/hero_background'
import { HeroIllustration } from '../../components/images/sso_admin_onboarding/hero'
import {
  SectionH2Image1,
  SectionH2Image2,
} from '../../components/images/sso_admin_onboarding/section_h2'
import { sectionH3Images } from '../../components/images/sso_admin_onboarding/section_h3'
import Layout from '../../components/layout'
import SectionH3 from '../../components/sections_h3'
import SectionWrapper from '../../components/sectionWrapper'
import SectionH2Base from '../../components/section_h2_base'
import SectionValuePropositions from '../../components/section_value_propositions'
import SvgSsoAdminOnboardingIcon from '../../components/svgs/icons/products/sso_admin_onboarding_icon'

const SsoAdminOnboardingPage = ({ pageContext: { data, localePath } }: any) => {
  return (
    <Layout
      helmetTitle={data.header_title.raw[0].text}
      helmetDescription={data.meta_description}
      helmetImage={data.meta_image.url}
      helmetUrl={`https://www.cryptr.co${localePath}/`}
    >
      <CenterHero
        title={data.header_title.raw}
        description={data.header_description.raw}
        textAlignment="center"
        category={data.category}
        pageID={data.slug}
        icon={<SvgSsoAdminOnboardingIcon />}
        call_to_action_color={data.slug}
        call_to_action={data.call_to_action}
      >
        <SectionWrapper color="base" type="image" identifier="hero" disabledPadding="padding-auto">
          <HeroIllustration />
        </SectionWrapper>
      </CenterHero>
      <SectionWrapper
        color="dark-blue"
        disabledPadding="padding-auto"
        identifier="developer-sso-admin-onboarding"
      >
        <SectionH2Base onlyWithChecks data={data.body[0]}>
          <SectionH2Image1 />
        </SectionH2Base>
      </SectionWrapper>
      <SectionWrapper disabledPadding="padding-auto">
        <SectionH3
          items={data.body[1].items}
          slug={data.slug}
          shadow={false}
          pictures={sectionH3Images.onboarding_links}
        />
      </SectionWrapper>
      <SectionWrapper color="dark-blue" disabledPadding="padding-auto">
        <SectionH2Base
          identifier="developer"
          callToAction
          onlyWithChecks
          data={data.body[2]}
          slug={data.slug}
        >
          <SectionH2Image2 />
        </SectionH2Base>
      </SectionWrapper>
      {/* <SectionWrapper disabledPadding="padding-auto">
        <SectionH3
          items={data.body[3].items}
          slug={data.slug}
          shadow={false}
          pictures={sectionH3Images.onboarding_complete}
        />
      </SectionWrapper> */}
      <SectionWrapper color="dark-blue" disabledPadding="top">
        <SectionValuePropositions
          alignment="align-left"
          slug={data.slug}
          valuePropositions={data.body[4].value_propositions}
        />
      </SectionWrapper>
      <CallToActionSection callToActionSectionData={data.body[5]} />
    </Layout>
  )
}

export default SsoAdminOnboardingPage
