import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'

export const SectionH2Image1 = () => {
  return (
    <StaticImage
      src="https://res.cloudinary.com/cryptr/image/upload/v1657124358/Cryptr%20www/Landing%20pages/SSO%20Admin%20Onboarding/sso_admin_onboarding_img_04_il0z68.jpg"
      alt="Onboarding Steps"
      imgStyle={{
        borderRadius: '0.5rem',
      }}
      quality={50}
      formats={['auto', 'webp', 'avif']}
      placeholder="none"
      className="image"
    />
  )
}

export const SectionH2Image2 = () => {
  return (
    <StaticImage
      src="https://res.cloudinary.com/cryptr/image/upload/v1657125189/Cryptr%20www/Landing%20pages/SSO%20Admin%20Onboarding/sso_admin_onboarding_img_02_nspqe7.jpg"
      alt="Create the admin onboarding"
      imgStyle={{
        borderRadius: '0.5rem',
      }}
      quality={50}
      formats={['auto', 'webp', 'avif']}
      placeholder="none"
      className="image"
    />
  )
}
