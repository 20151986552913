import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'

export const HeroIllustration = () => {
  return (
    <StaticImage
      src="https://res.cloudinary.com/cryptr/image/upload/v1657195666/Cryptr%20www/Landing%20pages/SSO%20Admin%20Onboarding/sso_admin_onboarding_img_01_obryrs.jpg"
      alt="SSO Admin Onboarding illustration"
      imgStyle={{
        borderRadius: '0.5rem',
      }}
      formats={['auto', 'webp', 'avif']}
      placeholder="none"
      className="image"
    />
  )
}
