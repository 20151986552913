import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'

export const sectionH3Images = {
  onboarding_links: [
    {
      img: (
        <StaticImage
          src="https://res.cloudinary.com/cryptr/image/upload/v1657126954/Cryptr%20www/Landing%20pages/SSO%20Admin%20Onboarding/sso_admin_onboarding_img_03_bjcmoo.jpg"
          alt="Onboarding links"
          imgStyle={{
            borderRadius: '0.5rem',
          }}
          quality={50}
          width={1080}
          formats={['auto', 'webp', 'avif']}
          placeholder="none"
        />
      ),
    },
  ],
  onboarding_complete: [
    {
      img: (
        <StaticImage
          src="https://res.cloudinary.com/cryptr/image/upload/v1657126954/Cryptr%20www/Landing%20pages/SSO%20Admin%20Onboarding/sso_admin_onboarding_img_05_cpwsah.jpg"
          alt="SSO configuration complete - Try SSO"
          imgStyle={{
            borderRadius: '0.5rem',
          }}
          quality={50}
          width={1080}
          formats={['auto', 'webp', 'avif']}
          placeholder="none"
        />
      ),
    },
  ],
}
